@import '~normalize.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/select/lib/css/blueprint-select.css';
@import '~@blueprintjs/datetime/lib/css/blueprint-datetime.css';

body,
html,
#root {
  width: 100%;
  height: 100%;
  font-family: Montserrat, -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
}

.bp3-button {
  transition: all 0.3s ease-in-out;
}

h1.bp3-heading,
h2.bp3-heading {
  margin: 0;
}

h6.bp3-heading {
  font-weight: 400;
}

.bp3-form-group label.bp3-label {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
}

:focus {
  outline: none;
}

.bp3-control-indicator {
  /* stylelint-disable declaration-no-important */
  outline: none !important;
}

a,
a:hover {
  text-decoration: none;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 100ms ease-in;
}

.global-link,
.global-link:hover {
  color: inherit;
}

.bp3-html-table td {
  box-shadow: none !important;
}

.global-spinner .bp3-spinner-animation {
  animation: pt-spinner-animation 1000ms linear infinite;
}

.global-spinner.light .bp3-spinner-animation path {
  stroke: rgb(255 255 255 / 0.2);
}

.global-spinner.light .bp3-spinner-animation path:last-child {
  stroke: rgb(255 255 255 / 0.8);
}

.bp3-toast-container-top {
  overflow: visible;
}

.bp3-daterangepicker
  .DayPicker-Day--hovered-range:not(
    .DayPicker-Day--selected,
    .DayPicker-Day--selected-range,
    .DayPicker-Day--selected-range-start,
    .DayPicker-Day--selected-range-end
  ) {
  background-color: transparent;
}

.bp3-daterangepicker .DayPicker-Day--hovered-range-start:not(.DayPicker-Day--hovered-range-end) {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.bp3-daterangepicker .DayPicker-Day--outside {
  visibility: visible;
}

.global-roles-menu-item.bp3-active {
  background-color: rgb(115 134 148 / 0.1) !important;
}

.global-roles-menu-item.bp3-active .bp3-icon {
  color: rgb(115 134 148 / 0.8) !important;
}

.global-more-button > span {
  transform: rotate(90deg);
}
